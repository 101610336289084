import styled from "styled-components";
// import media from "../../styles/media";

export const ResultsContainer = styled.div`
	header {
		display: flex;
		gap: 2rem;
		align-items: center;
		justify-content: center;
		img {
			max-height: 150px;
		}
	}
	main {
		padding: 1.5rem 0 0;
		big.pontuacao {
			display: block;
			font-weight: 700;
			color: white;
			text-align: center;
			font-size: 2rem;
			margin-bottom: 2rem;
		}
		.txtResult {
			margin-bottom: 3rem;
			p {
				line-height: 1.2em;
				color: white;
				font-size: 1.125rem;
				text-align: center;
				margin: 0 auto 25px;
				max-width: 500px;
				width: 100%;
			}
		}
		.recommendationsFlex {
			display: flex;
			justify-content: center;
			gap: 1rem;
			width: 100%;
			flex-wrap: wrap;
		}
	}
`;
