import styled from "styled-components";
import media from "../../media";

export const RecommendationCardContainer = styled.a`
	width: 25%;
	background-color: white;
	padding: 10px 10px 20px 10px;
	position: relative;
	transition: 0.3s ease;
	top: 0;
	border-radius: 3px;
	${media.lessThan("medium")`
		width: 100%;
	`}
	&:hover {
		top: -10px;
	}
	.capa {
		width: 100%;
		position: relative;
		height: 120px;
		overflow: hidden;
		margin: 0 0 10px;
		img {
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 100%;
			height: 100%;
		}
	}
	.titulo,
	.tipo {
		display: block;
	}
	.titulo {
		font-weight: 600;
		font-size: 1.125rem;
		color: #2c789a;
		margin: 0 0 10px;
		line-height: 1.2em;
	}

	.tipo {
		text-transform: uppercase;
		font-weight: 700;
		letter-spacing: 0.5px;
		color: #2c789a;
		opacity: 0.8;
		margin: 0 0 10px;
	}
	.resumo {
		line-height: 1.2em;
		color: #000;
		opacity: 0.65;
	}
`;
