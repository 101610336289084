import React from 'react';

import TagManager from 'react-gtm-module';

import { BrowserRouter } from 'react-router-dom'

import Router from './routes'

import { GlobalStyles } from './globalStyles'

import { QuestionsProvider } from './context/questionPovider'

TagManager.initialize({
  gtmId: 'GTM-PJ6RXB6'
})

function App() {
  return (
    <>
      <GlobalStyles />
      <QuestionsProvider>
        <BrowserRouter>
          <Router />
        </BrowserRouter>
      </QuestionsProvider>
    </>
  );
}

export default App;
