import { createGlobalStyle } from "styled-components";

export const GlobalStyles = createGlobalStyle`
    *{
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        border: 0;
        &:focus, &::visited{
            outline: none
        }
    }
    body {
        margin: 0;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }
    i {
        font-style: normal;
    }
    ul, li, ol {
        list-style: none;
        line-height: 1.2em;
    }
    body {
        font-size: 16px;
    }
    img {
        /* image-rendering: -webkit-optimize-contrast; // sharpen scaled down images */
        max-width: 100%;
    }
    button {
        cursor: pointer;
        &:disabled {
            cursor: default;
            filter: saturate(0) brightness(.7) !important;
            opacity: .8 !important;
        }
    }
    button, input, textarea, select {
        outline: none
    }
    a {
        text-decoration: none;
    }
    p, textarea {
        line-height: 1.2em;
    }
    .loading {
        width: 100%;
        display: flex;
        min-height: 200px;
        justify-content: center;
        align-items: center;
    }
`;

export const theme = {
	buttonColor: "#A8CF45",
};
