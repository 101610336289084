import styled from "styled-components";

import { transparentize } from "polished";

import fundo from "./assets/images/fundo.jpg";

export const AppContainer = styled.div`
	background: url(${fundo});
	min-height: 100vh;
	footer {
		background: ${transparentize(0.3, "#3d7e9c")};
		display: flex;
		justify-content: center;
		align-items: center;
		padding: 2rem;
		gap: 3rem;
	}
`;
