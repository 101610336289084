import styled from "styled-components";
import media from "../../media";
import { theme } from "../../globalStyles";

import loadingGif from "../../assets/images/loading.gif";

export const QuestionsContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 3rem;
	.left {
		width: 100%;
		max-width: 550px;
		h1 {
			text-align: center;
			margin-bottom: 2rem;

			${media.lessThan("medium")`
				margin-bottom: 0;
			`}
		}
		h2 {
			display: none;

			${media.lessThan("medium")`
				display: block;
				margin-bottom: 2rem;
			`}
		}
		.form {
			label {
				display: flex;
				gap: 10px;
				align-items: flex-start;
				color: white;
				line-height: 1.2em;
				margin-bottom: 16px;
				font-size: 1.125rem;
				font-weight: 600 !important;
				cursor: pointer;
				strong {
					font-weight: 600 !important;
				}
				em {
					font-style: normal;
				}
				${media.lessThan("medium")`
					font-size: 1rem;
				`}
				p {
					display: inline;
				}
			}
			input {
				margin-top: 4px;
				min-width: 20px;
			}
			button {
				width: 100%;
				border-radius: 3px;
				background: ${theme.buttonColor};
				padding: 0.8rem;
				font-size: 1.25rem;
				color: white;
				font-weight: 700;
				text-transform: uppercase;
				transition: 0.3s;

				position: relative;
				&:hover {
					filter: brightness(0.9);
				}
				&:disabled {
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url(${loadingGif}) no-repeat center;
						background-size: 40px;
					}
				}
				&.text {
					background: none;
					&:disabled {
						&:after {
							display: none;
						}
					}
				}
			}
			transition: 0.3s;
			opacity: 0;
			display: none;
			position: relative;
			z-index: 0;
			&.active {
				display: block;
				opacity: 1;
				z-index: 5;
			}
			&.buttons {
				display: flex;
				gap: 0.7rem;
			}
		}
		.paginas {
			width: 100%;
			text-align: center;
			font-weight: bold;
			font-size: 1.2rem;
			margin: 1rem 0;
			color: white;
		}
		.desc {
			width: 100%;
			text-align: center;
			margin: 0 0 2rem;
			color: white;
		}
	}
	.right {
		${media.lessThan("medium")`
			display: none;
		`}
		h2 {
			text-align: center;
			img {
				max-width: 400px;
				width: 70%;
			}
		}
		div {
			margin-top: 20px;
			text-align: center;
		}
	}
`;
