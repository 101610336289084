import React from "react";
import { IRecommendation } from "../../types/recommendation";
import { RecommendationCardContainer } from "./RecommendationCardStyled";
// import loadingGif from '../../assets/images/loading.gif'

export interface Props {
    recommendation: IRecommendation
}

const RecommendationCardWrapper: React.FC<Props> = ({
    recommendation,
    ...props
}: Props) => {
    const truncateText = (str: string, limit: number) => {
        if (str.length < limit) return str
        return `${str.substring(0, limit)}...`
    }
    return (
        <RecommendationCardContainer href={recommendation.url} {...props}>
            {recommendation.imagem &&
                <div className="capa">
                    <img src={recommendation.imagem} alt={recommendation.titulo} />
                </div>
            }
            <big className="titulo">{recommendation.titulo}</big>
            <small className="tipo">{recommendation.tipo}</small>
            {recommendation.descricao &&
                <p className="resumo">{truncateText(recommendation.descricao, 130)}</p>
            }
        </RecommendationCardContainer>
    );
};

export { RecommendationCardWrapper as RecommendationCard };
