import React, { ReactNode } from "react";
import { PageContainer } from "./PageContainerStyled";

export interface Props {
    children?: ReactNode;
}

const PageWrapper: React.FC<Props> = ({
    children,
    ...props
}: Props) => {
    return (
        <PageContainer {...props}>
            {children}
        </PageContainer>
    );
};

export { PageWrapper as PageContainer };
