import React, { useState } from "react";

import { HomeContainer } from './homeStyled'

import titulo from '../../assets/images/titulo.png'
import logoAcademia from '../../assets/images/logo-academia.png'
import foto from '../../assets/images/foto1.png'
import { PageContainer } from "../../components/PageContainer";
import { ILead } from "../../types/lead";
import { useNavigate } from "react-router-dom";
import { useQuestionContext } from "../../context/questionPovider";

export interface Props { }

const Home: React.FC<Props> = ({ ...props }) => {
    const [leadData, setLeadData] = useState<ILead>({} as ILead)
    const [loading, setLoading] = useState<boolean>(false)
    const { saveLead } = useQuestionContext()!
    const navigate = useNavigate()

    const handleLeadData = (e: any, field: "nome" | "email" | "nome_paciente" | "idade_paciente") => {
        setLeadData({ ...leadData, [field]: e.target.value })
    }
    const startTest = async () => {
        const emailRegEx = /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/

        const idadeRegEx = /^\d+$/

        if (!leadData.nome) return alert('Por favor insira o seu nome.')
        if (!leadData.email || !emailRegEx.test(leadData.email)) return alert('Por favor insira um email válido.')
        if (!leadData.nome_paciente) return alert('Por favor insira o nome da criança ou paciente.')
        if (
            !leadData.idade_paciente ||
            !idadeRegEx.test(leadData.idade_paciente.toString()) ||
            leadData.idade_paciente.toString().length > 2
        ) return alert('Por favor insira a idade da criança ou paciente. Insira um número maior que zero e menor que 100.')

        setLoading(true)

        try {
            await saveLead(leadData)

            navigate('/questionario')

        } catch (error) {
            alert('Houve um erro, por favor tente novamente mais tarde.')
            console.error(error)
        }

        setLoading(false)
    }

    return (
        <PageContainer>
            <HomeContainer>
                <div className="left">
                    <h1><img src={logoAcademia} alt="Academia do Autismo" /></h1>
                    <h2><img src={titulo} alt="Será que é Autismo?" /></h2>
                    <p>
                        O Transtorno do Espectro Autista (TEA) é um transtorno do neurodesenvolvimento caracterizado por déficits persistentes na interação, comunicação social e presença de comportamentos e interesses restritos e repetitivos (American Psychiatric Association, 2013). O teste a seguir, apresenta algumas características do Transtorno do Espectro Autista embasadas em escalas largamente utilizadas no mundo todo, como a M-CHAT e CARS.<br /><br />
                        <span>ATENÇÃO! O resultado não deve ser considerado, de forma alguma, um diagnóstico. Somente profissionais da saúde (psiquiatras e neurologistas) podem validar e fechar o diagnóstico de autismo.</span>
                    </p>
                    <div className="form">
                        <input onChange={(e) => handleLeadData(e, 'nome')} name="name" type="text" placeholder="Seu nome" required />
                        <input onChange={(e) => handleLeadData(e, 'email')} name="email" type="email" placeholder="Seu e-mail" required />
                        <input onChange={(e) => handleLeadData(e, 'nome_paciente')} name="patientName" type="text" placeholder="Nome da criança" required />
                        <input onChange={(e) => handleLeadData(e, 'idade_paciente')} name="patientAge" type="number" placeholder="Idade da criança" required />
                        <button onClick={startTest} disabled={loading}>Começar</button>
                    </div>
                </div>
                <div className="right">
                    <h1><img src={logoAcademia} alt="Academia do Autismo" /></h1>
                    <div><img src={foto} alt="" aria-hidden="true" /></div>
                </div>
            </HomeContainer>
        </PageContainer>
    );
};

export { Home };
