import React from "react";
import { PageContainer } from "../../components/PageContainer";

import { ResultsContainer } from './resultsStyled'

import titulo from '../../assets/images/titulo.png'
import logoAcademia from '../../assets/images/logo-academia.png'
import { useQuestionContext } from "../../context/questionPovider";

import { IRecommendation } from "../../types/recommendation";
import { RecommendationCard } from "../../components/RecommendationCard";

export interface Props { }

const Results: React.FC<Props> = ({ ...props }) => {
    const { result } = useQuestionContext()!;

    return (
        <PageContainer>
            <ResultsContainer>
                <header>
                    <h1><img src={logoAcademia} alt="Academia do Autismo" /></h1>
                    <h2><img src={titulo} alt="Será que é Autismo?" /></h2>
                </header>
                {result.pontuacao ? (<>
                    <main>
                        <big className="pontuacao">Pontuação Final: {result.pontuacao.split('.')[0]}</big>
                        <div className="txtResult">
                            <p>{result.texto_resumido}</p>
                            <p>Você receberá em instantes, no seu e-mail, um PDF com mais detalhes sobre o seu resultado. Enquanto isso, aproveite para conferir, abaixo, alguns conteúdos que podem ser úteis para você!</p>
                        </div>
                        {result.recomendacoes.length > 0 ?
                            <div className="recommendationsFlex">
                                {result.recomendacoes.map(
                                    (recommendation: IRecommendation) => <RecommendationCard recommendation={recommendation} />
                                )}
                            </div>
                            : 'Erro ao carregar recomendações'
                        }
                    </main>
                </>) : <main><div className="txtResult"><p>Sem Resultado</p></div></main>}
            </ResultsContainer>
        </PageContainer>
    );
};

export { Results };
