import React, { useState } from 'react'
import BaseService from '../services/base.service'

import { ILead } from '../types/lead'
import { IAnswerPayload, IFinalAnswer, IQuestion } from '../types/question'
import { IResult } from '../types/result'

type QuestionsContextType = {
    leadData: ILead
    stepOneId: number
    finalAswers: IFinalAnswer[]
    result: IResult
    saveLead: (l: ILead) => Promise<void>
    saveAnswers: (a: IFinalAnswer[]) => Promise<void>
    setAnswers: (a: IFinalAnswer[]) => void
}

const QuestionsContext = React.createContext<QuestionsContextType>({} as QuestionsContextType)

type Props = {
    children: React.ReactNode
}

export const QuestionsProvider = ({ children }: Props) => {
    const [leadData, setLeadData] = useState<ILead>({} as ILead)
    const [stepOneId, setStepOneId] = useState<number>(0)
    const [finalAswers, setFinalAnswers] = useState<IFinalAnswer[]>([])
    const [result, setResult] = useState<IResult>({} as IResult)

    const saveLead = async (newLeadData: ILead) => {
        setLeadData(newLeadData)

        const service = new BaseService('responder/etapa1')

        const response = await service.create(newLeadData)

        setStepOneId(response.id)
    }

    const saveAnswers = async (answers: IFinalAnswer[]) => {
        if (!stepOneId) return

        const service = new BaseService(`responder/etapa2`)

        const payload: IAnswerPayload[] = answers.map((q: IQuestion) => {
            return {
                questao: q.id,
                resposta: q.resposta
            }
        })

        const response = await service.responder(stepOneId, payload)

        setResult({ pontuacao: response.pontuacao, ...response.resultado })
    }

    const setAnswers = (answers: IFinalAnswer[]) => {
        setFinalAnswers(answers)
    }

    const value = {
        leadData,
        stepOneId,
        finalAswers,
        saveLead,
        saveAnswers,
        setAnswers,
        result
    }

    return <QuestionsContext.Provider value={value}>{children}</QuestionsContext.Provider>
}

export const useQuestionContext = () => React.useContext(QuestionsContext)