import styled from "styled-components";
import media from "../../media";
import { theme } from "../../globalStyles";
import loadingGif from "../../assets/images/loading.gif";

export const HomeContainer = styled.div`
	display: flex;
	justify-content: center;
	gap: 3rem;
	.left {
		width: 100%;
		max-width: 500px;
		h1 {
			text-align: center;
			display: none;
			${media.lessThan("medium")`
                display: block;
            `}
		}
		p {
			color: white;
			font-weight: 600;
			letter-spacing: 0.5px;
			line-height: 1.2em;
			margin: 2rem 0;
			span {
				color: #e6ff15;
			}
		}
		.form {
			input {
				width: 100%;
				padding: 0.7rem;
				border-radius: 3px;
				margin-bottom: 10px;
				&::placeholder {
					text-align: left;
				}
			}
			button {
				width: 100%;
				border-radius: 3px;
				background: ${theme.buttonColor};
				padding: 0.8rem;
				font-size: 1.25rem;
				color: white;
				font-weight: 700;
				text-transform: uppercase;
				transition: 0.3s;
				&:hover {
					filter: brightness(0.9);
				}
				&:disabled {
					&:hover {
						filter: brightness(0.7);
					}
					&:after {
						content: "";
						position: absolute;
						top: 0;
						left: 0;
						width: 100%;
						height: 100%;
						background: url(${loadingGif}) no-repeat center;
						background-size: 40px;
					}
				}
			}
		}
	}
	.right {
		h1 {
			text-align: right;
			margin-right: 30%;
		}
		div {
			margin-top: 40px;
		}
		${media.lessThan("medium")`
            display: none;
        `}
	}
`;
