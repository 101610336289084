import styled from "styled-components";

export const PageContainer = styled.div`
	display: flex;
	justify-content: center;
	width: 90%;
	max-width: 1200px;
	margin: 0 auto;
	padding: 3rem 0;
`;
